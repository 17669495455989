import React, { Component } from 'react';
import { Spinner, InputGroup, Form, Table, ButtonGroup, Button } from 'react-bootstrap';
import { logout } from '../helpers/auth';
import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import './Config.css';

const appTokenKey = "appToken";

export default class Config extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userName: '',
      configs: '',
      configs2: [{ id: 1, cat: 1, cols: 3, nombreCat: "Desarrollar la visión y la estrategia" },
      { id: 2, cat: 2, cols: 3, nombreCat: "Diseñar y desarrollar oferta de servicios" },
      { id: 3, cat: 3, cols: 3, nombreCat: "Promocionar y vender servicios" },
      { id: 4, cat: 4, cols: 3, nombreCat: "Prestar servicios de personalización y logística" },
      { id: 5, cat: 5, cols: 7, nombreCat: "Prestar servicios productivos" },
      { id: 6, cat: 6, cols: 2, nombreCat: "Administrar el servicio al cliente" },
      { id: 7, cat: 7, cols: 8, nombreCat: "Desarrollar y administrar el capital humano" },
      { id: 8, cat: 8, cols: 7, nombreCat: "Administrar las tecnologías de la información" },
      { id: 9, cat: 9, cols: 5, nombreCat: "Administrar los recursos financieros" },
      { id: 10, cat: 10, cols: 4, nombreCat: "Adquirir y administrar activos y servicios" },
      { id: 11, cat: 11, cols: 3, nombreCat: "Administrar el riesgo empresarial, el cumplimiento, la remediación y la resiliencia del negocio" },
      { id: 12, cat: 12, cols: 2, nombreCat: "Administrar las relaciones externas" },
      { id: 13, cat: 13, cols: 5, nombreCat: "Desarrollar y administrar las capacidades del negocio" }],
      isLoading: true,
    };

    this.handleLogout = this.handleLogout.bind(this);
  }

  renderButtons = () => {
    let buttons;

    buttons =
      <div>
        <ButtonGroup>
          <Button variant="outline-secondary" onClick={this.handleAddEvent.bind(this)}>Agregar Categoría</Button>
          <Button variant="outline-secondary" onClick={this.onSave.bind(this)}>Grabar</Button>
          <Button variant="outline-secondary" onClick={this.onCancel.bind(this)}>Cancelar</Button>
        </ButtonGroup>
      </div>;

    return buttons;
  }

  async componentDidMount() {
    this.getConfig();

  }

  getConfig() {
    firebase.auth().onAuthStateChanged(user => {

      if (user) {
        this.setState({ userName: user.displayName });
        this.setState({ isLoading: true });

        //query para extraer la config de las cats
        firebase.firestore().collection('configuracion').doc('configs').get().then(doc => {
          if (!doc.exists) {
            console.log('No such document!');
          } else {
            //console.log('Document data:', doc.data());
            //console.log(JSON.parse(doc.data().configs));

            this.setState({ configs: JSON.parse(doc.data().configs) });
            this.setState(this.state.configs);
            this.setState({ isLoading: false });
          }
        })
          .catch(err => {
            console.log('Error getting document', err);
          });

      }

    });

  }

  onSave() {
    const confirmed = window.confirm(
      "¿Está seguro que desea grabar los cambios?"
    );

    if (!confirmed) {
      return;
    }

    let configobject = JSON.stringify(this.state.configs);

    //console.log(configobject);

    firebase.firestore()
      .collection('configuracion')
      .doc('configs')
      .set(
        { configs: configobject }
      ).then(this.props.history.push({
        pathname: '/app/docs',
        data: { cat: 1 }
      }))
  }

  onCancel() {
    this.props.history.push({
      pathname: '/app/docs',
      data: { cat: 1 }
    })
  }

  handleLogout() {
    logout()
      .then(() => {
        localStorage.removeItem(appTokenKey);
        this.props.history.push("/login");
        console.log("User signed out from firebase");
      });
  }

  handleRowDel(config) {
    var index = this.state.configs.indexOf(config);
    this.state.configs.splice(index, 1);
    this.setState(this.state.configs);
  };

  handleAddEvent(evt) {
    var id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var config = {
      id: id,
      cat: 1,
      cols: 1,
      nombreCat: 'Nueva Categoría'
    }
    this.state.configs.push(config);
    this.setState(this.state.configs);

  }


  handleProductTable(evt) {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value
    };
    var configs = this.state.configs.slice();
    var newProducts = configs.map(function (config) {

      for (var key in config) {
        if (key == item.name && config.id == item.id) { //eslint-disable-line
          if (item.name === 'cat' || item.name === 'cols') {
            config[key] = parseInt(item.value);
          } else {
            config[key] = item.value;
          }
        }
      }
      return config;
    });
    this.setState({ configs: newProducts });
    //console.log(this.state.configs);
  };

  render() {

    return (
      <div className="custom">
        {this.renderButtons()}
        {this.state.isLoading ? (
          <div className="spinner">
            <Spinner animation="border" role="status" size="lg">
              <span className="sr-only">Espere un momento...</span>
            </Spinner>
          </div>
        ) : (
            <div className="custom">
              <ProductTable onProductTableUpdate={this.handleProductTable.bind(this)} onRowDel={this.handleRowDel.bind(this)} configs={this.state.configs} />
            </div>
          )}
      </div>
    );
  }
}

class ProductTable extends React.Component {

  render() {

    var onProductTableUpdate = this.props.onProductTableUpdate;
    var rowDel = this.props.onRowDel;
    var config = this.props.configs.map(function (config) {
      return (<ProductRow onProductTableUpdate={onProductTableUpdate} config={config} onDelEvent={rowDel.bind(this)} key={config.id} />)
    });

    return (
      <div className="custom2">
          <Table responsive bordered hover size="sm">
            <thead>
              <tr className="d-flex">
                <th className="col-sm-2"><h5><small>Número de la Categoría</small></h5></th>
                <th className="col-sm-2"><h5><small>Número de Columnas</small></h5></th>
                <th className="col-sm-6"><h5><small>Nombre de la Categoría</small></h5></th>
              </tr>
            </thead>
            <tbody>
              {config}
            </tbody>
          </Table>
      </div>
    );

  }

}

class ProductRow extends React.Component {
  onDelEvent() {
    this.props.onDelEvent(this.props.config);

  }

  render() {

    return (
      <tr className="d-flex">
        <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
          type: "cat",
          value: this.props.config.cat,
          id: this.props.config.id,
          className: "col-sm-2"
        }} />
        <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
          type: "cols",
          value: this.props.config.cols,
          id: this.props.config.id,
          className: "col-sm-2"
        }} />
        <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
          type: "nombreCat",
          value: this.props.config.nombreCat,
          id: this.props.config.id,
          className: "col-sm-6"
        }} />
        <td className="del-cell">
          <InputGroup.Prepend>
            <InputGroup.Text style={{ cursor: "pointer" }} id="deleteX" onClick={this.onDelEvent.bind(this)}>X</InputGroup.Text>
          </InputGroup.Prepend>
        </td>
      </tr>
    );

  }

}

class EditableCell extends React.Component {

  render() {
    return (
      <td className={this.props.cellData.className}>
        <Form.Control type="text" name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} placeholder="Normal text" /> 
      </td>
    );

  }

}





