import React, { Component, Fragment } from 'react';
import { logout } from '../helpers/auth';
import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import ImageMapper from 'react-image-mapper';
import mapaImg from './../assets/mapaPCPnivel1.PNG';
import './Map.css';

const appTokenKey = "appToken";

const MAP = {
  name: "my-map",
  areas: [
    { id: "1", shape: "rect", coords: [17, 160, 224, 284], }, //preFillColor: "rgba(0, 0, 0, 0.5)"
    { id: "12", shape: "rect", coords: [17, 310, 224, 428], },
    { id: "2", shape: "rect", coords: [430, 210, 250, 334], },
    { id: "3", shape: "rect", coords: [630, 210, 440, 334], },
    { id: "5", shape: "rect", coords: [872, 126, 640, 272], },
    { id: "4", shape: "rect", coords: [872, 278, 640, 420], },
    { id: "6", shape: "rect", coords: [1080, 210, 880, 334], },
    { id: "7", shape: "rect", coords: [30, 648, 440, 534], },
    { id: "8", shape: "rect", coords: [448, 648, 758, 534], },
    { id: "11", shape: "rect", coords: [765, 648, 1058, 534], },
    { id: "9", shape: "rect", coords: [73, 761, 446, 653], },
    { id: "10", shape: "rect", coords: [453, 761, 658, 653], },
    { id: "13", shape: "rect", coords: [665, 761, 1022, 653], },
  ]
};

export default class Map extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userName: '',
      width: 0,
      height: 0,
    };

    this.handleLogout = this.handleLogout.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  async componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ userName: user.displayName });
      }
    });
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    if (window.innerWidth > 900) {
      this.setState({ width: 900 });
    } else {
      this.setState({ width: window.innerWidth });
    };
    this.setState({ height: window.innerHeight });
  }

  handleLogout() {
    logout()
      .then(() => {
        localStorage.removeItem(appTokenKey);
        this.props.history.push("/login");
        console.log("User signed out from firebase");
      });
  }

  getInitialState() {
    return { hoveredArea: null, msg: null, moveMsg: null };
  }

  load() {
    this.setState({ msg: "Interact with image !" });
  }

  clicked(area) {
    this.props.history.push({
      pathname: '/app/docs',
      data: { cat: area.id }
    })
  }

  clickedOutside(evt) {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    this.setState({
      msg: `You clicked on the image at coords ${JSON.stringify(coords)} !`
    });
  }

  moveOnImage(evt) {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    this.setState({
      moveMsg: `You moved on the image at coords ${JSON.stringify(coords)} !`
    });
  }

  enterArea(area) {
    this.setState({
      hoveredArea: area,
      msg: `You entered ${area.shape} ${area.name} at coords ${JSON.stringify(
        area.coords
      )} !`
    });
  }

  leaveArea(area) {
    this.setState({
      hoveredArea: null,
      msg: `You leaved ${area.shape} ${area.name} at coords ${JSON.stringify(
        area.coords
      )} !`
    });
  }

  moveOnArea(area, evt) {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    this.setState({
      moveMsg: `You moved on ${area.shape} ${
        area.name
        } at coords ${JSON.stringify(coords)} !`
    });
  }

  getTipPosition(area) {
    return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
  }

  render() {
    return (
      <Fragment>
        <div className="center">
          <ImageMapper src={mapaImg} map={MAP} active={false}
            width={this.state.width} imgWidth={1098}
            onLoad={() => this.load()}
            onClick={area => this.clicked(area)}
            onMouseEnter={area => this.enterArea(area)}
            onMouseLeave={area => this.leaveArea(area)}
            onMouseMove={(area, _, evt) => this.moveOnArea(area, evt)}
            onImageClick={evt => this.clickedOutside(evt)}
            onImageMouseMove={evt => this.moveOnImage(evt)}
          />
          {
            this.state.hoveredArea &&
            <span className="tooltip"
              style={{ ...this.getTipPosition(this.state.hoveredArea) }}>
              {this.state.hoveredArea && this.state.hoveredArea.name}
            </span>
          }
        </div>
      </Fragment>
    );
  }

}

