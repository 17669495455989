import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for database
import 'firebase/firestore';   // for cloud firestore
//import 'firebase/messaging';   // for cloud messaging
//import 'firebase/functions';   // for cloud functions

const config = {
    apiKey: "AIzaSyCjCYpMN1VsK-3z1vKzdES0v0YLTvbSYSI",
    authDomain: "processmapdoc.firebaseapp.com",
    databaseURL: "https://processmapdoc.firebaseio.com",
    projectId: "processmapdoc",
    storageBucket: "processmapdoc.appspot.com",
    messagingSenderId: "83619156926",
    appId: "1:83619156926:web:abb1f428d917ba0e3b5fef",
    measurementId: "G-JWK211P7E2"
};
firebase.initializeApp(config);

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const firebaseAuth = firebase.auth;
export const db = firebase.firestore();
