import React, { Component, Fragment } from 'react';
import { loginWithGoogle } from '../helpers/auth';
import firebase from 'firebase/app';
import "firebase/auth";
import { firebaseAuth } from '../config/constants';
import { Row, Col, Card } from 'react-bootstrap';
import './Login.css';

const firebaseAuthKey = 'firebaseAuthInProgress';
const appTokenKey = 'appToken';
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            splashScreen: false,
            isLoading: false,
        };
    }

    handleGoogleLogin = () => {
        loginWithGoogle()
            .catch(err => {
                localStorage.removeItem(firebaseAuthKey)
            });
        // this will set the splashscreen until its overridden by the real firebaseAuthKey
        localStorage.setItem(firebaseAuthKey, '1');
    }

    componentDidMount() {
        // checks if we are logged in, if we are go to the map route
        if (localStorage.getItem(appTokenKey)) {
            //this.props.history.push('/');
            return;
        }
        firebaseAuth().onAuthStateChanged(user => {
            if (user) {
                localStorage.removeItem(firebaseAuthKey);
                localStorage.setItem(appTokenKey, user.uid);
                //this.props.history.push('/')
            }
        })
    }

    render() {
        if (localStorage.getItem(firebaseAuthKey) === '1')
            return <Splashscreen />;
        return <LoginPage handleGoogleLogin={this.handleGoogleLogin} />;
    }
}

// this is the URL we copied from firebase storage
const loginButtonUrl = 'https://firebasestorage.googleapis.com/v0/b/processmapdoc.appspot.com/o/google-icon-white.png?alt=media&token=fa197125-6bf6-477e-8a63-53995a56e237';

const styles = {
    backgroundImage: `url(${loginButtonUrl})`
}

const LoginPage = ({ handleGoogleLogin }) => (

    <div className="login-container">
        <Fragment>
            <Row>
                <Col></Col>
                <Col xs={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Mapa de Procesos y Base Documental</Card.Title>
                            <Card.Text>
                                Esta aplicación permite el diseño de un mapa de procesos corporativo y almanecar la documentación asociada.
                            </Card.Text>
                            <div onClick={handleGoogleLogin} className="google-btn">
                                <div style={styles} className="google-icon-wrapper">
                                    <img className="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" alt=""/>
                                </div>
                                <p className="btn-text" onClick={() => auth.signInWithRedirect(provider)}><b>Ingresa con Google</b></p>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col></Col>
            </Row>
            </Fragment>
    </div>

)

const Splashscreen = () => (<p>Por favor espere...</p>);
