import React, { Component, Fragment } from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import './Home.css';

//const appTokenKey = "appToken";

export default class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
    };

  }

  renderLander() {
    return (
      <div className="home-container">
        <Fragment>
          <Row>
            <Col></Col>
            <Col xs={8}>
              <Card>
                <Card.Body>
                  <Card.Title>Mapa de Procesos y Base Documental</Card.Title>
                  <Card.Text>
                    Esta aplicación permite el diseño de un mapa de procesos corporativo y almanecar la documentación asociada.
                  </Card.Text>
                  <Button as={Link} to="/login" variant="primary">Ingresar</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col></Col>
          </Row>
        </Fragment>
      </div>
    );
  }

  renderLanderInt() {
    return (
      <div className="home-container">
        <Fragment>
          <Row>
            <Col></Col>
            <Col xs={8}>
              <Card>
                <Card.Body>
                  <Card.Title>Mapa de Procesos y Base Documental</Card.Title>
                  <Card.Text>
                    Esta aplicación permite el diseño de un mapa de procesos corporativo y almanecar la documentación asociada.
                  </Card.Text>
                  <Button as={Link} to="/app/map" variant="primary">Ir al mapa</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col></Col>
          </Row>
        </Fragment>
      </div>
    );
  }

  render() {

    return (
      <div>
        {this.props.isAuthenticated ? this.renderLanderInt() : this.renderLander()}
      </div>
    );
  }
}