import React, { Component } from 'react';
import { Col, Spinner, DropdownButton, ButtonGroup, Dropdown, Form, Button, Modal, InputGroup } from 'react-bootstrap';
import { logout } from '../helpers/auth';
//import FileUploader from 'react-firebase-file-uploader';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss'
import './react-grid-layout-styles.css';
import './react-resizable-styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import './Docs.css';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const appTokenKey = "appToken";

function highestCounterFunction(items) {
  return items.reduce(function (previous, entry) {
    return previous === undefined || parseInt(entry.i) > previous ? parseInt(entry.i) : previous;
  }, undefined);
}

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) // eslint-disable-line
  );
}

export default class Docs extends Component {

  constructor(props) {
    super(props);

    this.state = {
      layouts: {},
      editable: false,
      modalShow: false,
      newCounter: 1,
      items: [],
      files: [],
      //downloadURLs: [],
      isUploading: false,
      uploadProgress: 0,
      id: 1,
      editModal: false,
      cat: 1, //parseInt(this.props.location.data.cat),
      uploadingSuccess: false,
      filename: '',
      filenameExists: false,
      filenameLoading: false,
      userName: '',
      isLoading: true,
      displayColorPickertextc: false,
      displayColorPickerbgc: false,
      colorbgc: {
        hex: '#FF0000',
      },
      colortextc: {
        hex: '#FF0000',
      },
    };

    //var result = options.filter(obj => {return obj.cat === parseInt(this.props.location.data.cat)})[0].cols
    //console.log(result);

    this.handleLogout = this.handleLogout.bind(this);
    this.getInitialItems = this.getInitialItems.bind(this);
    this.getItems = this.getItems.bind(this);
    this.handleCrearProceso = this.handleCrearProceso.bind(this);
    this.handleRemoveProceso = this.handleRemoveProceso.bind(this);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
    //this.onBreakPointChange = this.onBreakPointChange.bind(this);
    this.saveProceso = this.saveProceso.bind(this);
    this.handleUploadSuccess = this.handleUploadSuccess.bind(this);
  }

  static defaultProps = {
    className: "layout",
    //cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 50,
    // This turns off compaction so you can place items wherever.
    compactType: null,
    preventCollision: false,
  };

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = (progress) => this.setState({ uploadProgress: progress });
  handleUploadError = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleSelect(eventKey, event) {
    this.setState({ cols: this.state.options[eventKey].cols });
    //console.log(this.state.options[eventKey].cat);
    //console.log(this.state.options[eventKey].cols);
    this.setState({ cat: this.state.options[eventKey].cat }, () => this.getItems(this.state.cat));
    //console.log(this.state.options[eventKey]);
  }

  async handleUploadSuccess(filename) {

    let fileNameObject = {};
    let docUuid = uuidv4();

    try {
      let { bucket, fullPath } = await firebase.storage().ref('docs').child(filename).getMetadata();
      //console.log('fullPath', fullPath)
      let downloadURL = await firebase.storage().ref('docs').child(filename).getDownloadURL();
      //console.log('downloadURL', downloadURL)

      fileNameObject.fullPath = fullPath;
      fileNameObject.id = docUuid;
      fileNameObject.url = downloadURL;
      this.setState(oldState => ({
        files: [...oldState.files, fileNameObject],
        //downloadURLs: [...oldState.downloadURLs, downloadURL],
        uploadProgress: 100,
        isUploading: false
      }));
      //console.log('files:', this.state.files);

      let { uid, email, displayName } = await firebase.auth().currentUser;

      let newDoc = {
        i: this.state.idp,
        url: downloadURL,
        userName: displayName,
        userId: uid,
        email,
        bucket,
        fullPath
      }
      //console.log('newDoc', newDoc);

      await firebase.firestore().collection('docs').doc(docUuid).set(newDoc);

      //this.setState({ isUploading: false });
      this.setState({ uploadingSuccess: true });
      this.setState({ filenameExists: true });
      //this.setState({ filename: filename });
      //this.setState({ url: downloadURL });
    }

    catch (err) {
      console.error(err);
    }
  }

  handleShow = (PID, nombre, descripcion, i, bgc, textc) => {
    this.setState({ filenameLoading: true });

    let archRef = firebase.firestore().collection('docs');
    archRef.where('i', '==', i).get()
      .then(snapshot => {
        if (snapshot.empty) {
          //console.log('No matching files.');
          this.setState({ files: [],
            filenameExists: false,
            filenameLoading: false });
          return;
        }

        let files = [];
        snapshot.forEach(doc => {
          var newItem = doc.data();
          newItem.id = doc.id;
          files.push(newItem);
        });

        this.setState({ files });
        this.setState({
          filenameExists: true,
          filenameLoading: false
          //files: doc.data().fullPath,
          //url: doc.data().url,
        })
        //console.log('files:');
        //console.log(files);

      })
      .catch(err => {
        console.log('Error getting files', err);
      });

    this.setState({
      modalShow: true,
      PID: PID,
      nombre: nombre,
      descripcion: descripcion,
      id: i,
      idp: i,
      bgc,
      textc: textc,
    })
  }

  handleHide = () => {
    this.setState({
      modalShow: false,
      editModal: false,
      files: [],
      filenameExists: false,
      uploadingSuccess: false,
    })
  }

  handleEdit = () => {
    this.setState({
      editModal: true
    })
  }

  createElement = (el) => {
    const removeStyle = {
      position: "absolute",
      right: "5px",
      top: 0,
      cursor: "pointer"
    };

    const i = el.add ? "+" : el.i;

    //replace cuenta cuantos . hay en el string
    if (this.state.editable === false && el.PID.replace(/[^.]/g, "").length < 2 && (el.PID.endsWith(".0") === false)) {
      return (
        <div key={i} data-grid={el} className="card card-body contain" style={{ background: el.bgc, color: el.textc }}>
          {(
            <span className="add text font-weight-bold" style={{ fontSize: 13 }} onClick={() => this.handleShow(el.PID, el.nombre, el.descripcion, el.i, el.bgc, el.textc)}>{el.PID} {el.nombre}</span>
          )}
        </div>
      )
    } else if (this.state.editable === false && el.PID.endsWith(".0")) {
      return (
        <div key={i} data-grid={el} className="card card-body contain" style={{ background: el.bgc, color: el.textc }}>
          {(
            <span className="add text font-weight-bold" style={{ fontSize: 18 }} onClick={() => this.handleShow(el.PID, el.nombre, el.descripcion, el.i, el.bgc, el.textc)}>{el.PID} {el.nombre}</span>
          )}
        </div>
      )
    } else if (this.state.editable === false && el.PID.length > 3) {
      return (
        <div key={i} data-grid={el} className="card card-body contain" style={{ background: el.bgc, color: el.textc }}>
          {(
            <span className="add text" style={{ fontSize: 13 }} onClick={() => this.handleShow(el.PID, el.nombre, el.descripcion, el.i, el.bgc, el.textc)}>{el.PID} {el.nombre}</span>
          )}
        </div>
      )
    } else if (el.PID.replace(/[^.]/g, "").length < 2 && (el.PID.endsWith(".0") === false)) {
      return (
        <div key={i} data-grid={el} className="card card-body contain" style={{ background: el.bgc, color: el.textc }}>
          {(
            <span className="text font-weight-bold" style={{ fontSize: 13 }}>{el.PID} {el.nombre}</span>
          )}
          <span
            className="remove"
            style={removeStyle}
            onClick={this.onRemoveItem.bind(this, i)}
          >
            x
          </span>
        </div>

      );
    } else if (el.PID.endsWith(".0")) {
      return (
        <div key={i} data-grid={el} className="card card-body contain" style={{ background: el.bgc, color: el.textc }}>
          {(
            <span className="text font-weight-bold" style={{ fontSize: 18 }}>{el.PID} {el.nombre}</span>
          )}
          <span
            className="remove"
            style={removeStyle}
            onClick={this.onRemoveItem.bind(this, i)}
          >
            x
          </span>
        </div>

      );
    } else {
      return (
        <div key={i} data-grid={el} className="card card-body contain" style={{ background: el.bgc, color: el.textc }}>
          {(
            <span className="text" style={{ fontSize: 13 }}>{el.PID} {el.nombre}</span>
          )}
          <span
            className="remove"
            style={removeStyle}
            onClick={this.onRemoveItem.bind(this, i)}
          >
            x
          </span>
        </div>

      );
    }
  }

  toggleEditMode = () => {
    this.setState({
      editable: !this.state.editable
    });
  }

  toggleSave = () => {
    try {
      //Get a new write batch to update Layouts
      //console.log("layouts antes de grabar: " + this.state.layouts.lg);
      this.state.layouts.lg.forEach(doc => {
        firebase.firestore().collection('layouts').doc(doc.i).set({
          h: doc.h,
          i: doc.i,
          moved: false,
          static: false,
          w: doc.w,
          x: doc.x,
          y: doc.y,
          minW: 0,
          maxW: 12,
          minH: 0,
          maxH: 12,
          isDraggable: true,
          isResizable: true,
          cat: this.state.cat
        }, { merge: true });
      })
    }
    catch (err) {
      console.error(err);
    }

    this.setState({
      editable: !this.state.editable
    });
  }

  async handleCrearProceso(bgc, textc, nombre, descripcion, cat, PID) {
    try {
      let { uid, email, displayName } = await firebase.auth().currentUser;
      let newProceso = {
        userName: displayName,
        userId: uid,
        email,
        i: this.state.newCounter.toString(),
        x: 0,
        y: this.state.newCardIntialPosition,
        w: 1,
        h: 1,
        bgc: bgc,
        textc: textc,
        nombre: nombre,
        descripcion: descripcion,
        cat: cat,
        PID: PID,
      }
      //console.log('newProceso', newProceso.nombre);
      await firebase.firestore().collection('procesos').doc(newProceso.i).set(newProceso);
      // Increment the counter to ensure key is always unique.
      this.setState({ newCounter: this.state.newCounter });
    }
    catch (err) {
      console.error(err);
    }
  }

  onAddProceso = () => {
    /*eslint no-console: 0*/
    //console.log("agregando", "Proceso " + this.state.newCounter);
    this.setState({
      // Add a new item. It must have a unique key!
      items: this.state.items.concat({
        i: this.state.newCounter.toString(),
        x: 0,
        y: this.state.newCardIntialPosition,
        w: 1,
        h: 1,
        bgc: "#f9f9f9",
        textc: "#060d13",
        nombre: 'Proceso ' + this.state.newCounter,
        descripcion: "Describir el proceso.",
        cat: this.state.cat,
        PID: this.state.cat + '.1.' + this.state.newCounter.toString(),
      }),
    }, () => this.handleCrearProceso("#f9f9f9", "#060d13", 'Proceso ' + this.state.newCounter, "Describir el proceso.", this.state.cat, this.state.cat + '.1.' + this.state.newCounter.toString()));
  }

  onAddTexto = () => {
    /*eslint no-console: 0*/
    //console.log("agregando", "Proceso " + this.state.newCounter);
    this.setState({
      // Add a new item. It must have a unique key!
      items: this.state.items.concat({
        i: this.state.newCounter.toString(),
        x: 0,
        y: this.state.newCardIntialPosition,
        w: 1,
        h: 1,
        bgc: "#f0f0f0",
        textc: "#686866",
        nombre: 'Texto ' + this.state.newCounter,
        descripcion: "Describir.",
        cat: this.state.cat,
        PID: 'TXT',
      }),
    }, () => this.handleCrearProceso("#f9f9f9", "#060d13", 'Proceso ' + this.state.newCounter, "Describir.", this.state.cat, ''));
  }

  onAddGrupoProcesos = () => {
    /*eslint no-console: 0*/
    //console.log("agregando", "Grupo de Procesos " + this.state.newCounter);
    this.setState({
      // Add a new item. It must have a unique key!
      items: this.state.items.concat({
        i: this.state.newCounter.toString(),
        x: 0,
        y: this.state.newCardIntialPosition,
        w: 1,
        h: 1,
        bgc: "#DEEAF6",
        textc: "#3F709E",
        nombre: 'Grupo de Procesos ' + this.state.newCounter,
        descripcion: "Describir el grupo de procesos.",
        cat: this.state.cat,
        PID: this.state.cat + '.' + this.state.newCounter.toString(),
      }),
    }, () => this.handleCrearProceso("#DEEAF6", "#3F709E", 'Grupo de Procesos ' + this.state.newCounter, "Describir el grupo de procesos.", this.state.cat, this.state.cat + '.' + this.state.newCounter.toString()));
  }

  onAddCategoria = () => {
    /*eslint no-console: 0*/
    //console.log("agregando", "Categoria " + this.state.newCounter);
    let bgctemp = '';
    if (this.state.cat === 1 || this.state.cat === 12) {
      bgctemp = '#428bca'
    } else if (this.state.cat > 1 && this.state.cat < 7) {
      bgctemp = '#eb9234'
    } else {
      bgctemp = '#73AC44'
    };

    this.setState({
      // Add a new item. It must have a unique key!
      items: this.state.items.concat({
        i: this.state.newCounter.toString(),
        x: 0,
        y: this.state.newCardIntialPosition,
        w: 1,
        h: 1,
        bgc: bgctemp,
        textc: "#ffffff",
        nombre: 'Categoria ' + this.state.newCounter,
        descripcion: "Describir la categoría de procesos.",
        cat: this.state.cat,
        PID: this.state.newCounter.toString() + '.0',
      }),
    }, () => this.handleCrearProceso(bgctemp, "#ffffff", 'Categoria ' + this.state.newCounter, "Describir la categoría de procesos.", this.state.cat, this.state.newCounter.toString() + '.0'));
  }

  onAddCategoriaMap = () => {
    /*eslint no-console: 0*/
    //console.log("agregando", "Categoria " + this.state.newCounter);
    this.setState({
      // Add a new item. It must have a unique key!
      items: this.state.items.concat({
        i: this.state.newCounter.toString(),
        x: 0,
        y: this.state.newCardIntialPosition,
        w: 1,
        h: 1,
        bgc: "#5f9cd3",
        textc: "#ffffff",
        nombre: 'Categoria ' + this.state.newCounter,
        descripcion: "Describir la categoría de procesos.",
        cat: this.state.cat,
        PID: this.state.newCounter.toString() + '.0',
      }),
    }, () => this.handleCrearProceso("#5f9cd3", "#ffffff", 'Categoria ' + this.state.newCounter, "Describir la categoría de procesos.", this.state.cat, this.state.newCounter.toString() + '.0'));
  }

  // We're using the cols coming back from this to calculate where to add new items.
  onBreakpointChange = (breakpoint, cols) => {
    this.setState({
      breakpoint: breakpoint,
      cols: cols
    });
  }

  async onLayoutChange(layout, layouts) {
    //saveToLS("layouts", layouts);
    this.setState({ layouts });
    //console.log("layouts on change: " + layouts.lg);
  }

  onRemoveItem = (i) => {
    const confirmed = window.confirm(
      "¿Está seguro que desea eliminar este proceso? Al eliminarlo se perderán todos los datos del proceso y sus archivos adjuntos. Esta operación no se puede deshacer."
    );

    if (!confirmed) {
      return;
    }
    //console.log("removiendo ", i);
    this.setState({ items: _.reject(this.state.items, { i: i }) },
      () => this.handleRemoveProceso(i))
  }

  onRemoveFile = (i) => {
    console.log("removiendo archivo: ", i);

    const filesTemp = this.state.files;
    const filesFiltered = filesTemp.filter(function (obj) {
      return obj.id !== i;
    });
    //console.log(filesFiltered);
    //console.log(filesFiltered.length);

    if (filesFiltered.length === 0) { this.setState({ filenameExists: false }); }
    this.setState({ files: filesFiltered },
      () => this.handleRemoveFile(i))
  }

  async handleRemoveFile(i) {
    //console.log('file deleted: ', i)
    await firebase.firestore().collection('docs').doc(i).delete();
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClickColorbgc = () => {
    this.setState({ displayColorPickerbgc: !this.state.displayColorPickerbgc })
  };

  handleCloseColorbgc = () => {
    this.setState({ displayColorPickerbgc: false })
  };

  handleChangeColorbgc = (colorbgc) => {
    this.setState({ colorbgc: colorbgc });
    this.setState({ bgc: colorbgc.hex });
  };

  handleClickColortextc = () => {
    this.setState({ displayColorPickertextc: !this.state.displayColorPickertextc })
  };

  handleCloseColortextc = () => {
    this.setState({ displayColorPickertextc: false })
  };

  handleChangeColortextc = (colortextc) => {
    this.setState({ colortextc: colortextc })
    this.setState({ textc: colortextc.hex });
  };

  async saveProceso(PID, nombre, descripcion, idp, bgc, textc) {
    try {
      let doc = {
        i: idp,
      };
      firebase.firestore().collection('procesos').doc(doc.i).set({
        PID: PID,
        nombre: nombre,
        descripcion: descripcion,
        bgc: bgc,
        textc: textc
      }, { merge: true });
    }
    catch (err) {
      console.error(err);
    }

    const items = this.state.items;

    //find the index of object from array that you want to update
    const objIndex = items.findIndex(obj => obj.i === idp);
    //console.log("objIndex=", objIndex);

    // make new object of updated object.   
    const updatedItem = { ...items[objIndex], descripcion: descripcion, nombre: nombre, PID: PID, bgc: bgc, textc: textc };
    //updatedItem = { ...items[objIndex], nombre: nombre };
    //console.log("updated item=", updatedItem);

    // make final new array of objects by combining updated object.
    const updatedItems = [...items.slice(0, objIndex), updatedItem, ...items.slice(objIndex + 1),];

    this.setState({
      items: updatedItems,
      modalShow: false,
      editModal: false,
      files: [],
      filenameExists: false,
      uploadingSuccess: false,
    })

  }

  renderFiles = (files) => {
    let filesrender;

    if (this.state.filenameLoading) {
      filesrender = 
      <Spinner animation="border" role="status" variant="secondary" size="sm"></Spinner>
      
    } else if (!this.state.filenameLoading && this.state.filenameExists) {
      filesrender = files.map((field, i) => {
        return this.state.filenameExists && this.state.editModal ?
          <div key={i}>
            <Form.Label><a rel="noopener noreferrer" href={field.url} target="_blank">{field.fullPath}</a></Form.Label>
            <button type="button" className="close" onClick={this.onRemoveFile.bind(this, field.id)}>
              <span aria-hidden="true">&times;</span><span className="sr-only">Eliminar</span>
            </button>
          </div>
          :
          <div key={i}>
            <Form.Label><a rel="noopener noreferrer" href={field.url} target="_blank">{field.fullPath}</a></Form.Label>
          </div>
      });
    } else {
      filesrender = <Form.Label>N/A</Form.Label>
    }

    return filesrender;
  }

  modalWindow = (PID, nombre, descripcion, idp, files, url, bgc, textc) => {

    const styles = reactCSS({
      'default': {
        colorbgc: {
          width: '36px',
          height: '20px',
          background: bgc,
        },
        colortextc: {
          width: '36px',
          height: '20px',
          background: textc,
        },
        swatch: {
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    if (this.state.modalShow === true) {
      return (
        <div>
          <Modal size="lg" show={this.state.modalShow} onHide={this.handleHide}>
            <Modal.Header closeButton>
              Descripción del Proceso
            </Modal.Header>
            <Modal.Body>
              <Form.Row>
                <Form.Group as={Col} md="2">
                  <Form.Label>Código</Form.Label>
                  <Form.Control
                    required
                    as="input"
                    disabled={!this.state.editModal}
                    name='PID'
                    placeholder="Código"
                    value={PID}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md="10">
                  <Form.Label>Nombre del Proceso</Form.Label>
                  <Form.Control
                    required
                    as="input"
                    disabled={!this.state.editModal}
                    name='nombre'
                    placeholder="Nombre del Proceso"
                    value={nombre}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>Descripción</Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    disabled={!this.state.editModal}
                    name='descripcion'
                    placeholder="Descripción del proceso."
                    value={descripcion}
                    onChange={this.handleChange}
                    rows="5"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>Archivos Adjuntos</Form.Label>
                  <Form.Row />
                  {this.renderFiles(files)}
                </Form.Group>
              </Form.Row>
              {this.state.editModal && <Form.Row>
                <Form.Group as={Col} md="12">
                <CustomUploadButton
                    hidden
                    storageRef={firebase.storage().ref('docs')}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={this.handleUploadSuccess}
                    onProgress={this.handleProgress}
                    multiple
                    className={'btn btn-secondary'}
                  >
                  Seleccionar Archivos
                  </CustomUploadButton>
                  {this.state.isUploading &&
                    <span className="text-muted btn" style={{cursor: "default"}}>
                    <Spinner animation="border" role="status" variant="secondary" size="sm"></Spinner>
                    </span>
                  }
                  {this.state.uploadingSuccess && <span className="text-muted btn" style={{cursor: "default"}}><FontAwesomeIcon style={{ color: "#5cb85c" }} icon={faCheck} /></span>}
                </Form.Group>
              </Form.Row>}
              {this.state.editModal && <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>Color del Fondo</Form.Label>
                  <InputGroup>
                    <Form.Control aria-describedby="inputGroupAppend" as="input" disabled={!this.state.editModal} name='bgc' value={bgc} onChange={this.handleChange} />
                    <InputGroup.Append >
                      <InputGroup.Text style={{ backgroundColor: "#ffffff" }}>
                        <div style={styles.swatch} onClick={this.handleClickColorbgc}>
                          <div style={styles.colorbgc} />
                        </div>
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  {this.state.displayColorPickerbgc ? <div style={styles.popover}>
                    <div style={styles.cover} onClick={this.handleCloseColorbgc} />
                    <SketchPicker name='bgc' color={this.state.colorbgc} onChange={this.handleChangeColorbgc} />
                  </div> : null}
                  <Form.Label>Color del Texto</Form.Label>
                  <InputGroup>
                    <Form.Control aria-describedby="inputGroupAppend" as="input" disabled={!this.state.editModal} name='textc' value={textc} onChange={this.handleChange} />
                    <InputGroup.Append>
                      <InputGroup.Text style={{ backgroundColor: "#ffffff" }}>
                        <div style={styles.swatch} onClick={this.handleClickColortextc}>
                          <div style={styles.colortextc} />
                        </div>
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  {this.state.displayColorPickertextc ? <div style={styles.popover}>
                    <div style={styles.cover} onClick={this.handleCloseColortextc} />
                    <SketchPicker name='textc' color={this.state.colortextc} onChange={this.handleChangeColortextc} />
                  </div> : null}
                </Form.Group>
              </Form.Row>}
            </Modal.Body>
            <Modal.Footer>
              {this.state.editModal ? (
                <React.Fragment>
                  <Button variant="primary" onClick={() => this.saveProceso(PID, nombre, descripcion, idp, bgc, textc)}>
                    Grabar
                  </Button>
                  <Button variant="secondary" onClick={this.handleHide}>
                    Cancelar
              </Button></React.Fragment>
              ) : (<React.Fragment>
                <Button variant="primary" onClick={this.handleEdit}>
                  Editar
                </Button>
                <Button variant="secondary" onClick={this.handleHide}>
                  Cerrar
              </Button></React.Fragment>
                )}
            </Modal.Footer>
          </Modal>
        </div>
      )
    } else {
      return null
    }

  }

  renderButtons = () => {
    let buttons;

    let optionstemp = this.state.options.filter(function (obj) { return obj.cat !== 0; });

    if (this.state.editable === true && this.state.cat !== 0) {
      buttons =
        <div>
          <ButtonGroup>
            <Button variant="outline-secondary" onClick={this.onAddCategoria}>+ Categoria</Button>
            <Button variant="outline-secondary" onClick={this.onAddGrupoProcesos}>+ Grupo de Procesos</Button>
            <Button variant="outline-secondary" onClick={this.onAddProceso}>+ Proceso</Button>
            <Button variant="outline-secondary" onClick={this.toggleSave}>Grabar</Button>
          </ButtonGroup>
        </div>;
    } else {
      buttons =
        <div>
          <ButtonGroup>
            <DropdownButton variant="outline-secondary" title="Categoria" id="document-type" onSelect={this.handleSelect.bind(this)}>
              {optionstemp.map((opts, i) => (
                <Dropdown.Item key={opts.cat} eventKey={i}>{opts.cat + '. ' + opts.nombreCat}</Dropdown.Item>
              ))}
            </DropdownButton>
            <Button variant="outline-secondary" onClick={this.toggleEditMode}>Editar</Button>
          </ButtonGroup>
        </div>
    }

    return buttons;
  }

  async handleRemoveProceso(i) {
    //console.log('proceso deleted: ', i)
    await firebase.firestore().collection('procesos').doc(i).delete();
    await firebase.firestore().collection('layouts').doc(i).delete();
    await firebase.firestore().collection('docs').doc(i).delete();
  }

  async componentDidMount() {

    let initialCat = 1
    if (typeof this.props.location !== undefined && this.props.location.data !== undefined && typeof this.props.location.data.cat !== undefined) {
      this.setState({ cat: parseInt(this.props.location.data.cat) });
      initialCat = parseInt(this.props.location.data.cat);
      //console.log(this.props.location.data.cat);
    }

    this.getInitialItems(initialCat);
  }

  getInitialItems(cat) {
    firebase.auth().onAuthStateChanged(user => {

      if (user) {
        //this.setState({ userName: user.displayName });
        this.setState({ isLoading: true });

        //query para extraer la config de las cats
        firebase.firestore().collection('configuracion').doc('configs').get().then(doc => {
          if (!doc.exists) {
            console.log('No such document!');
          } else {
            //console.log('Document data:', doc.data());
            //console.log(JSON.parse(doc.data().configs));

            this.setState({ options: JSON.parse(doc.data().configs) });
            this.setState({ cols: this.state.options.filter(obj => { return obj.cat === parseInt(this.state.cat) })[0].cols });
            //this.setState({ cols: this.state.options.filter(obj => { return obj.cat === parseInt(this.props.location.data.cat) })[0].cols }); 
            //console.log(this.state.options.filter(obj => { return obj.cat === parseInt(this.props.location.data.cat) })[0].cols);
          }
        })
          .catch(err => {
            console.log('Error getting document', err);
          });

        //query para encontrar el id mas alto
        firebase.firestore().collection('procesos').onSnapshot(snapshot => {
          let hightcounteritems = [];
          snapshot.forEach(doc => {
            var newItem = {};
            newItem.i = doc.data().i;
            hightcounteritems.push(newItem);
          });

          this.setState({ newCounter: highestCounterFunction(hightcounteritems) + 1 || 1 });
          //console.log(highestCounterFunction(hightcounteritems) + 1);
        });

        //query para extraer los procesos de la cat seleccionada
        let itemsRef = firebase.firestore().collection('procesos');
        itemsRef.where('cat', '==', cat).get()
          .then(snapshot => {
            if (snapshot.empty) {
              //console.log('No matching documents.');
              this.setState({ items: [] });
              return;
            }

            let items = [];
            snapshot.forEach(doc => {
              var newItem = doc.data();
              //newItem.id = doc.id;
              items.push(newItem);
            });

            this.setState({ items });
            //console.log('items:');
            //console.log(items);

          })
          .catch(err => {
            console.log('Error getting documents', err);
          });

        //query para extraer los layouts de la cat seleccionada
        let layoutsRef = firebase.firestore().collection('layouts');
        layoutsRef.where('cat', '==', cat).get()
          .then(snapshot => {
            if (snapshot.empty) {
              //console.log('No matching documents.');
              this.setState({ layouts: {} });
              this.setState({ isLoading: false });
              return;
            }

            let layoutstemp = [];
            snapshot.forEach(doc => {
              var newItem = doc.data();
              layoutstemp.push(newItem);
            });

            let layouts = {};
            layouts = { lg: layoutstemp };

            //busca el y mas alto de la columna 0 para nuevos procesos
            let layoutFiltered = layoutstemp.filter(function (el) {
              return el.x === 0
            });

            let newCardIntialPosition = 0;
            newCardIntialPosition = Math.max.apply(Math, layoutFiltered.map(function (o) { return o.y; })) + 1
            this.setState({ newCardIntialPosition: newCardIntialPosition });

            this.setState({ layouts });
            this.setState({ isLoading: false });
            //console.log('layout:');
            //console.log(layout);

          })
          .catch(err => {
            console.log('Error getting documents', err);
          });

      }

    });

  }

  getItems(cat) {
    firebase.auth().onAuthStateChanged(user => {

      if (user) {
        this.setState({ isLoading: true });

        //set cols para la nueva cat
        this.setState({ cols: this.state.options.filter(obj => { return obj.cat === cat })[0].cols });
        //console.log(this.state.options.filter(obj => { return obj.cat === cat })[0].cols);

        //query para encontrar el id mas alto
        firebase.firestore().collection('procesos').onSnapshot(snapshot => {
          let hightcounteritems = [];
          snapshot.forEach(doc => {
            var newItem = {};
            newItem.i = doc.data().i;
            hightcounteritems.push(newItem);
          });

          this.setState({ newCounter: highestCounterFunction(hightcounteritems) + 1 || 1 });
          //console.log(highestCounterFunction(hightcounteritems) + 1);
        });

        //query para extraer los procesos de la cat seleccionada
        let itemsRef = firebase.firestore().collection('procesos');
        itemsRef.where('cat', '==', cat).get()
          .then(snapshot => {
            if (snapshot.empty) {
              //console.log('No matching documents.');
              this.setState({ items: [] });
              return;
            }

            let items = [];
            snapshot.forEach(doc => {
              var newItem = doc.data();
              //newItem.id = doc.id;
              items.push(newItem);
            });

            this.setState({ items });
            //console.log('items:');
            //console.log(items);

          })
          .catch(err => {
            console.log('Error getting documents', err);
          });

        //query para extraer los layouts de la cat seleccionada
        let layoutsRef = firebase.firestore().collection('layouts');
        layoutsRef.where('cat', '==', cat).get()
          .then(snapshot => {
            if (snapshot.empty) {
              //console.log('No matching documents.');
              this.setState({ layouts: {} });
              this.setState({ isLoading: false });
              return;
            }

            let layoutstemp = [];
            snapshot.forEach(doc => {
              var newItem = doc.data();
              layoutstemp.push(newItem);
            });

            let layouts = {};
            layouts = { lg: layoutstemp };

            //busca el y mas alto de la columna 0 para nuevos procesos
            let layoutFiltered = layoutstemp.filter(function (el) {
              return el.x === 0
            });

            let newCardIntialPosition = 0;
            newCardIntialPosition = Math.max.apply(Math, layoutFiltered.map(function (o) { return o.y; })) + 1
            this.setState({ newCardIntialPosition: newCardIntialPosition });

            this.setState({ layouts });
            this.setState({ isLoading: false });
            //console.log('layout:');
            //console.log(layout);

          })
          .catch(err => {
            console.log('Error getting documents', err);
          });

      }

    });

  }

  handleLogout() {
    logout()
      .then(() => {
        localStorage.removeItem(appTokenKey);
        this.props.history.push("/login");
        console.log("User signed out from firebase");
      });
  }

  render() {

    const {
      editable,
      nombre,
      descripcion,
      idp,
      files,
      url,
      bgc,
      textc,
      PID,
    } = this.state;

    //console.log('layouts:');
    //console.log(this.state.layouts.lg);
    //console.log('items:');
    //console.log(this.state.items);
    //console.log(this.state.options);

    return (
      <div className="docscontent">
        {this.modalWindow(PID, nombre, descripcion, idp, files, url, bgc, textc)}
        {!this.state.isLoading && this.renderButtons()}
        {this.state.isLoading ? (
          <div className="spinner">
            <Spinner animation="border" role="status" size="lg">
              <span className="sr-only">Espere un momento...</span>
            </Spinner>
          </div>
        ) : (<div>
          <ResponsiveReactGridLayout
            className="layout"
            cols={{ lg: this.state.cols, md: 10, sm: 6, xs: 4, xxs: 2 }}
            rowHeight={30}
            layouts={this.state.layouts}
            onLayoutChange={(layout, layouts) =>
              this.onLayoutChange(layout, layouts)
            }
            {...this.props}
            onBreakpointChange={this.onBreakpointChange}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            isDraggable={editable}
            isResizable={editable}
          >
            {_.map(this.state.items, el => this.createElement(el))}
          </ResponsiveReactGridLayout>
        </div>
          )}
      </div>
    );
  }
}