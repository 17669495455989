import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { logout } from './helpers/auth';
import firebase from 'firebase/app';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import Routes from "./Routes";
import './App.css';

const appTokenKey = "appToken";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      userName: "",
      isAdmin: false,
    };
  }

  async componentDidMount() {
    try {
      await firebase.auth().onAuthStateChanged(user => {
        if (user) {
          this.setState({ userName: user.displayName });
          this.userHasAuthenticated(true);
        }
      });
    }
    catch (e) {
      if (e !== 'No current user') {
        //alert(e);
        alert("Su sesión de usuario expiró, por favor ingrese nuevamente.");
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  }

  userName = userName => {
    this.setState({ userName: userName });
  }

  isAdmin = isadmin => {
    this.setState({ isAdmin: isadmin });
  }

  handleLogout = () => {
    logout()
      .then(() => {
        localStorage.removeItem(appTokenKey);
        this.userHasAuthenticated(false);
        //this.userHasAuthenticated(false);
        console.log("User signed out from firebase");
        this.props.history.push("/");
      });
  }

  renderNav() {
    let navbar;

      navbar =
        <div>
          <Navbar collapseOnSelect bg="light" expand="lg">
            <Navbar.Brand as={Link} to="/">Mapa de Procesos</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
              <Nav.Link as={Link} to="/app/map">Mapa</Nav.Link>
                <Nav.Link as={Link} to="/app/reports">Reportes</Nav.Link>
                <Nav.Link as={Link} to="/app/config">Configuración</Nav.Link>
              </Nav>
              <Nav>
                <NavDropdown title={this.state.userName} id="collasible-nav-dropdown">
                  <NavDropdown.Item onClick={this.handleLogout}>Salir</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>

    return navbar;
  }

  onLoad = (data) => {
    console.log(data);
  }

  /*renderLander = () => {
    return (
      <Container fluid={true}>
        {this.renderNav()}
        <div className="content">
        <Routes childProps={childProps} />
        </div>
      </Container>
    )
  }*/

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      userName: this.state.userName,
      cat: this.state.cat,
    };
    //{this.props.isAuthenticated ? this.renderLanderInt() : this.renderLander()}
    return (
      !this.state.isAuthenticating &&
      <div>
        {this.state.isAuthenticated
          ? <Container fluid={true}>
            {this.renderNav()}
            <div className="content">
              <Routes childProps={childProps} />
            </div>
          </Container>
          : <Container fluid={true}>
            <div className="content">
              <Routes childProps={childProps} />
            </div>
          </Container>
        }
      </div>
    );
  }
}

export default withRouter(App);
