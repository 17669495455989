import React, { Component, Fragment } from 'react';
import { Spinner, Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import { logout } from '../helpers/auth';
import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import './Reportes.css';

const appTokenKey = "appToken";
const { SearchBar } = Search;

export default class Config extends Component {

  constructor(props) {
    super(props);

    this.state = {
      procesos: [],
      selected: [],
      isLoading: true,
      isExporting: false,
      exportText: 'Exportar a Excel'
    };

    this.handleLogout = this.handleLogout.bind(this);
  }

  async componentDidMount() {
    this.getProcesos();

  }

  getProcesos() {
    firebase.auth().onAuthStateChanged(user => {

      if (user) {
        this.setState({ userName: user.displayName });
        this.setState({ isLoading: true });

        //query para extraer la config de las cats
        let itemsRef = firebase.firestore().collection('procesos');
        itemsRef.get()
          .then(snapshot => {
            if (snapshot.empty) {
              //console.log('No matching documents.');
              this.setState({ procesos: [] });
              return;
            }

            let procesostemp = [];
            snapshot.forEach(doc => {
              var newItem = doc.data();
              procesostemp.push(newItem);
            });

            //saco todos los que no son procesos
            let procesosFiltered = procesostemp.filter(function (el) {
              return el.PID !== ''
            });

            this.setState({ procesos: procesosFiltered });
            this.setState({ isLoading: false });
          })
          .catch(err => {
            console.log('Error getting documents', err);
          });

      }

    });

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selected !== this.state.selected) {
      if (this.state.selected.length > 0) { this.setState({ exportText: 'Exportar Selección a Excel' }); } else { this.setState({ exportText: 'Exportar a Excel' }); }
    }
  }

  onCancel() {
    this.props.history.push({
      pathname: '/app/docs',
      data: { cat: 1 }
    })
  }

  handleLogout() {
    logout()
      .then(() => {
        localStorage.removeItem(appTokenKey);
        this.props.history.push("/login");
        console.log("User signed out from firebase");
      });
  }

  handleGetSelectedData = () => {
    console.log(this.state.selected);
  }

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row.i]
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter(x => x !== row.i)
      }));
    }
  }

  handleOnSelectAll = (isSelect) => {
    const is = this.state.procesos.map(r => r.i);
    if (isSelect) {
      this.setState(() => ({
        selected: is
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  }

  exportFile = () => {

    this.setState({ isExporting: true });

    //dejo solo los procesos seleccionados si selected no es 0
    let selectedtemp = this.state.selected;
    if (selectedtemp.length > 0) {
      var filteredProcesses = this.state.procesos.filter(function (item) {
        return selectedtemp.indexOf(item.i) !== -1;
      });
    } else {
      filteredProcesses = this.state.procesos
    }

    let subsetarray = filteredProcesses.map(function (obj) {
      return {
        PID: obj.PID,
        cat: obj.cat,
        nombre: obj.nombre,
        descripcion: obj.descripcion,
        //createdAt: new moment(obj.createdAt).format('DD/MM/YYYY, h:mm:ss a'),
        //updatedAt: new moment(obj.updatedAt).format('DD/MM/YYYY, h:mm:ss a'),
      };
    });

    let datosprocesos = subsetarray.map(Object.values);
    let encabezado = [
      "PID",
      "Cat",
      "Nombre",
      "Descripción",
    ];
    datosprocesos = [encabezado, ...datosprocesos];

    import('xlsx').then(XLSX => {
      // do something with it // async load package --- very important for page loading speed (webpack does split a new chunck doing this, if it is not imported the classic way in another compoment)
      /* convert array to workbook */
      const ws = XLSX.utils.aoa_to_sheet(datosprocesos);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Procesos");
      /* generate XLSX file and send to client */
      XLSX.writeFile(wb, "Procesos Export.xlsx");
      this.setState({ isExporting: false });
    });

    this.setState({ isLading: false });
  }

  renderButtons = () => {
    let buttons;

    buttons =
      <div>
        <ButtonGroup>
          <Button variant="outline-secondary" onClick={this.exportFile}>{this.state.exportText}</Button>
        </ButtonGroup>
        <span className="text-muted btn" disabled={true}>{this.state.procesos.length} registros ({this.state.selected.length} seleccionados).}</span>
      </div>;

    return buttons;
  }

  renderProcesosList() {
    
    const defaultSorted = [{
      dataField: 'PID', // if dataField is not match to any column you defined, it will be ignored.
      order: 'asc' // desc or asc
    }];

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      selected: this.state.selected,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
      style: { backgroundColor: '#c6dcff' },
    };

    const columns = [{
      dataField: 'PID',
      text: 'PID',
      footer: "",
      hidden: false,
      sort: true,
      headerStyle: () => {
        return {
          width: "5%",
          backgroundColor: '#f8f9fa'
        }
      },
    }, {
      dataField: 'cat',
      text: 'Cat',
      footer: "",
      hidden: false,
      sort: true,
      headerStyle: () => {
        return {
          width: "5%",
          backgroundColor: '#f8f9fa'
        }
      },
    }, {
      dataField: 'nombre',
      text: 'Nombre',
      sort: true,
      footer: "",
      hidden: false,
      headerStyle: () => {
        return {
          width: "30%",
          backgroundColor: '#f8f9fa'
        }
      },
    }, {
      dataField: 'descripcion',
      text: 'Descripcion',
      sort: true,
      footer: "",
      hidden: false,
      headerStyle: () => {
        return {
          width: "60%",
          backgroundColor: '#f8f9fa'
        }
      },
    }];

    //var columnTogglePropsfiltered = [];

    return (
      <div className="Procesos">
        <ToolkitProvider
          keyField="i"
          bootstrap4
          data={this.state.procesos}
          columns={columns}
          search={{
            searchFormatted: false
          }}
          columnToggle
        >
          {
            props => (
              <div>
                <Row>
                  <Col xs={12} sm={9}>
                    {!this.state.isLoading && this.renderButtons()}
                  </Col><Col xs={12} sm={3}><SearchBar {...props.searchProps} /></Col></Row>
                <BootstrapTable
                  classes="procesost"
                  ref={n => this.node = n}
                  noDataIndication="Esta tabla no contiene elementos."
                  striped
                  hover
                  condensed
                  bordered={false}
                  defaultSorted={defaultSorted}
                  selectRow={selectRow}
                  {...props.baseProps}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </div>
    );
  }

  render() {
    return (
      <div className="reportes">
        {this.state.isLoading ? (
          <div className="spinner">
            <Spinner animation="border" role="status" size="lg">
              <span className="sr-only">Espere un momento...</span>
            </Spinner>
          </div>
        ) : (
            <Fragment>
              {this.renderProcesosList()}
            </Fragment>
          )}
      </div>
    );
  }
}


