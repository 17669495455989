import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./helpers/AppliedRoute";
import AuthenticatedRoute from "./helpers/AuthenticatedRoute";
import UnauthenticatedRoute from "./helpers/UnauthenticatedRoute";

import Docs from "./features/Docs";
import Login from "./features/Login";
import Map from "./features/Map";
import Home from "./features/Home";
import Config from "./features/Config";
import Reportes from "./features/Reportes";
import NotFound from "./features/NotFound";

//import Loadable from 'react-loadable';
//import { Glyphicon } from "react-bootstrap";
//import "./Routes.css";

/*const LoadingSpinner = ({isLoading, error}) => {
  // Handle the loading state
  if (isLoading) {
    return <div className={'LoaderSpinner'} style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '80vh'}}><Glyphicon glyph="refresh" className="spinning"/></div>;
  }
  // Handle the error state
  else if (error) {
    return <div>Lo sentimos, hubo un problema cargando la página.</div>;
  }
  else {
    return null;
  }
};

const AsyncHome = Loadable({
  loader: () => import("./containers/Home"),
  loading: LoadingSpinner, //era LoadingSpinner withouth commas
  delay: 300
});*/

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <AuthenticatedRoute path="/app/map" exact component={Map} props={childProps} />
    <AuthenticatedRoute path="/app/docs" exact component={Docs} props={childProps} />
    <AuthenticatedRoute path="/app/config" exact component={Config} props={childProps} />
    <AuthenticatedRoute path="/app/reports" exact component={Reportes} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;
  